import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import MarbleEdgeImg from '../../images/marble-edge.png'
import Header from 'components/theme/Header'
import './layout.css'
import 'unnamed'

export default ({ children, isLoggedIn, logout }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <>
      <Header
        siteTitle={site.siteMetadata.title}
        isLoggedIn={isLoggedIn}
        logout={logout}
      />
      <LayoutWrapper>
        {children}
        <MarbleEdge src={MarbleEdgeImg} />
      </LayoutWrapper>
    </>
  )
}

const LayoutWrapper = styled.div`
  min-height: calc(100vh - 100px);
  background: ${D.cream};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


const MarbleEdge = styled.img`
  position: relative;
  margin-bottom:-10px;
`;