import { Link } from 'gatsby'
import { navigate } from "gatsby"
import styled from 'styled-components';
import React, { useState, useEffect, useContext } from 'react'
import { D } from '@dayetopia/library';
import IconDayeLogo from '../../svgs/daye.svg'

export default function Header({ siteTitle, isLoggedIn, logout }) {

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', animateScroll);
    return () => {
      window.removeEventListener('scroll', animateScroll);
    };
  }, [])

  function animateScroll() {
    if (window.pageYOffset > 100) {
      setAnimate(true);
      return;
    }
    setAnimate(false);
  }
  return (
    <OuterContainer>
      <Container>
        <DayeIcon
        />
      </Container>
    </OuterContainer>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const OuterContainer = styled.div`
 
  top: 0;
  background: ${D.cream};
  width: 100vw;
  padding: 0;
  z-index: 1;
  animation: fadein 2s;
  box-shadow: none;
  transition: box-shadow 0.5s;
`;

const DayeIcon = styled(IconDayeLogo)`
  width: 130px;
  height: 100px;
  padding: 15px;
  cursor: pointer;
`;